<template>
  <div class="vue-currency-input-component">
    <currency-input-base
      v-if="currentCOA.currency_iso"
      v-model="val"
      :inputClass="inputClass"
      :options="options"
      :key="renderKey"
      :disabled="disabled"
      :state="state"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <b-form-input
      v-if="!currentCOA.currency_iso"
      :class="inputClass"
      :disabled="true"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInputBase from './CurrencyInputBase'

export default {
  name: 'CurrencyInput',
  components: { CurrencyInputBase },
  props: {
    value: {
      type: [Number, String],
      default: 0.0
    },
    inputClass: {
      type: [String, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: -999999999.99
    },
    max: {
      type: Number,
      default: 999999999.99
    },
    precision: {
      type: Number,
      default: 2
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      val: 0.0,
      renderKey: 0,
      rangeLimits: { min: -999999999.99, max: 999999999.99 },
      isFocused: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    options () {
      if (!this.currentCOA.currency_iso) {
        return {}
      }

      return {
        currency: this.currentCOA.currency_iso,
        value: this.val,
        modelValue: this.val,
        precision: parseInt(this.precision),
        valueRange: {
          min: this.min > this.rangeLimits.min ? this.min : this.rangeLimits.min,
          max: this.max < this.rangeLimits.max ? this.max : this.rangeLimits.max
        }
      }
    }
  },
  methods: {
    onInput (inputVal) {
      if (inputVal === null) {
        this.val = 0.0
      }
      this.$emit('input', inputVal)
    },
    onFocus () {
      this.isFocused = true
    },
    onBlur (event) {
      this.isFocused = false
      if (this.val === null || this.value === null) {
        this.val = 0.0
        this.renderKey++
        this.$emit('input', this.val)
      }
    },
    $rerender (forceOnFocus = false) {
      if ((this.isFocused && forceOnFocus) || !this.isFocused) {
        this.renderKey++
      }
    }
  },
  mounted () {
    this.val = this.value !== null ? Number(this.value) : 0.0
  },
  watch: {
    value () {
      this.val = Number(this.value)
      if (this.value === null) {
        this.renderKey++
        this.$emit('input', this.val)
      }
    }
  }
}
</script>
