<template>
  <div class="vue-currency-input-base-component">
    <input
      type="text"
      :class="calculatedInputClass"
      ref="inputRef"
      :disabled="disabled"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInputBase',
  props: {
    value: {
      type: Number,
      default: 0.0
    },
    options: {
      type: Object,
      default () { return {} }
    },
    inputClass: {
      type: [String, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    calculatedInputClass () {
      const oResultCssClasses = { 'form-control': true }
      if (typeof this.inputClass === 'string') {
        const splittedClasses = this.inputClass.split(' ').filter(i => i.length)
        splittedClasses.map(cssClass => {
          oResultCssClasses[cssClass] = true
        })
      } else if (typeof this.inputClass === 'object') {
        Object.keys(this.inputClass).map(cssClass => {
          oResultCssClasses[cssClass] = this.inputClass[cssClass]
        })
      }

      if (this.state !== null) {
        oResultCssClasses['is-valid'] = this.state
        oResultCssClasses['is-invalid'] = !this.state
      }

      return oResultCssClasses
    }
  },
  setup (props) {
    if (props.options.currency) {
      const { inputRef } = useCurrencyInput(props.options)
      return { inputRef }
    }
  },
  methods: {
    onFocus (event) {
      this.$emit('focus', event)
    },
    onBlur (event) {
      this.$emit('blur', event)
    }
  }
}
</script>
